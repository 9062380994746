import { Currency, CurrencyAmount, Price, Token, USD } from '@telefy/teleswap-core-sdk'
import { useMemo } from 'react'
import { SupportedChainId as ChainId } from '../constants/chains'
import { USDC } from '../constants/tokens'
import { useV2TradeExactOut } from './useV2Trade'
import { useBestV3TradeExactOut } from './useBestV3Trade'
import { useActiveWeb3React } from './web3'
import { useTelePrice } from 'services/graph'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price<Currency, Token> | undefined {
  const { chainId } = useActiveWeb3React()

  const amountOut = chainId ? STABLECOIN_AMOUNT_OUT[chainId] : undefined
  const stablecoin = amountOut?.currency

  const v2USDCTrade = useV2TradeExactOut(currency, amountOut, {
    maxHops: 2,
  })
  const v3USDCTrade = { trade: null }

  return useMemo(() => {
    if (!currency || !stablecoin) {
      return undefined
    }

    // handle usdc
    if (currency?.wrapped.equals(stablecoin)) {
      return new Price(stablecoin, stablecoin, '1', '1')
    }

    // use v2 price if available, v3 as fallback
    if (v2USDCTrade) {
      const { numerator, denominator } = v2USDCTrade.route.midPrice
      return new Price(currency, stablecoin, denominator, numerator)
    }
    // else if (v3USDCTrade.trade) {
    //   const { numerator, denominator } = v3USDCTrade.trade.route.midPrice
    //   return new Price(currency, stablecoin, denominator, numerator)
    // }

    return undefined
  }, [currency, stablecoin, v2USDCTrade, v3USDCTrade.trade])
}

export function useUSDCValue(currencyAmount: CurrencyAmount<Currency> | undefined | null) {
  const price = useUSDCPrice(currencyAmount?.currency)

  return useMemo(() => {
    if (!price || !currencyAmount) return null
    try {
      return price.quote(currencyAmount)
    } catch (error) {
      return null
    }
  }, [currencyAmount, price])
}

export const useUSDCTeleAmount = (amount: number): number | undefined => {
  const { chainId } = useActiveWeb3React()
  const { data: telePrice } = useTelePrice(chainId || ChainId.MAINNET)
  if (telePrice) {
    return telePrice * amount
  }
  return undefined
}

// StableCoin amounts used when calculating spot price for a given currency.
// The amount is large enough to filter low liquidity pairs.
export const STABLECOIN_AMOUNT_OUT: { [chainId: number]: CurrencyAmount<Token> } = {
  [ChainId.MAINNET]: CurrencyAmount.fromRawAmount(USD[ChainId.MAINNET], 100_000e6),
  [ChainId.ROPSTEN]: CurrencyAmount.fromRawAmount(USD[ChainId.ROPSTEN], 100_000e6),
  [ChainId.KOVAN]: CurrencyAmount.fromRawAmount(USD[ChainId.KOVAN], 100_000e1),
  [ChainId.ARBITRUM_ONE]: CurrencyAmount.fromRawAmount(USD[ChainId.ARBITRUM_ONE], 100_000e6),
  [ChainId.CELO]: CurrencyAmount.fromRawAmount(USD[ChainId.CELO], 100_000e18),
  [ChainId.FUSE]: CurrencyAmount.fromRawAmount(USD[ChainId.FUSE], 100_000e6),
}
